/**
 * Attempts to give focus to an element.
 */
export function focus(el, select = false, delay = 0) {
    setTimeout(() => {
        let $el = $(el);

        // Look for text inputs that are visible.
        let target = "input:visible,textarea:visible";

        // If the element provided isn't already a focusable element, attempt
        // to focus the first focusable descendant.
        if ( ! $el.is(target)) {
            $el = $el.find(target);
        }

        // Only care about the first matched element.
        $el = $el.eq(0);

        // Focus the input, if any.
        $el.focus();

        // Optionally select all text in the field.
        if (select) {
            $el.select();
        }
    }, delay);
}

export function startPageLoading() {
    return window.ELEMENT.Loading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
    });
}

/**
 * Registers a date-time picker using a given set of options and optional event
 * handler. This will also work for dynamically created elements.
 *
 * @deprecated We should use Element datepickers and move away from Bootstrap.
 */
export function registerDatePicker(selector, options, handler) {
    let defaults = {
        format:          "YYYY-MM-DD HH:mm:ss",
        locale:          lang.getLocale(),
        viewMode:        "days",
        showClear:       true,
        showTodayButton: true,
        useCurrent:      true,
    };

    // Merge defaults with provided options.
    options = _.merge({}, defaults, options);

    if (options.minDate) {
        options.minDate = moment(options.minDate, options.format);
    }

    if (options.maxDate) {
        options.maxDate = moment(options.maxDate, options.format);
    }

    $("body").on("focus", selector, function (e) {
        let el = $(e.target);

        // Only init if not already loaded.
        if (!el.data("DateTimePicker")) {
            el.datetimepicker(options);
        } else {

            // For some reason the datepicker resets to day view on close,
            // so reset this whenever the field receives focus.
            if (options.viewMode) {
                el.data("DateTimePicker").viewMode(options.viewMode);
            }
        }
    });

    $("body").on("dp.change", selector, function (e) {
        if (!e.date) {
            $(e.target).data("DateTimePicker").clear();
            $(e.target).data("DateTimePicker").hide();

            if (handler) {
                handler(e);
            }
        }
    });

    $("body").on("dp.hide", selector, function (e) {
        if (e.date) {
            $(this).trigger("change", {
                date: e.date,
            });

            if (handler) {
                handler(e);
            }
        }
    });
}

/**
 * @deprecated We should use Element datepickers and move away from Bootstrap.
 */
export function destroyDatePicker(selector) {
    let el = $(selector);

    // Only init if not already loaded.
    if (el.data("DateTimePicker")) {
        el.data("DateTimePicker").destroy();
    }
}
